<template>
    <div class="codeLogin">
        <div class="img_title"><img class="indexImg" alt="himi logo" src="../../assets/images/li_logo.png"></div>
        <div class="welcomeDiv">
            <div>欢迎登录，</div>
            <div>海米互联网公寓</div>
        </div>
        <div class="inputPhone">
            <div class="label">+86</div>
            <div class="line">|</div>
            <input  v-model="moblieValue" type="text" placeholder="请输入手机号" maxlength="11" @blur="checkMoblie()" />
        </div>
        <div class="inputCode">
            <input type="text"  placeholder="请输入验证码"/>
<!--            v-model="code"-->
            <button class="getCode" v-show="show"  @click="getCode()">获取验证码</button>
            <button v-show="!show" disabled class="count">{{count}} S后重发</button>
        </div>
        <div class="otherLogin">
            <div @click="pwdlogin" class="pwdLogin">密码登录</div>
        </div>
        <div class="errorDiv">
            <div v-show="errorShow"  class="errorInfo">
                {{msg}}
            </div>
        </div>
        <div class="btnDiv">
            <div class="btn"><button class="loginBtn"  type="submit" >登录</button></div>
            <div class="btn">
                <button class="getWechat" type="submit">
                    <img class="wechatLogo" src="../../assets/images/wechatlogo.png">
                    微信授权
                </button>
            </div>
        </div>
        <div class="point">
            温馨提示：未注册的用户，登录时将自动注册，且代表您已同意<span>《用户服务协议》《隐私协议》</span>
        </div>
    </div>

</template>
<script>

import {sendCode} from "../../getData/getData";
import {responseUtil} from "../../libs/rongxunUtil";

export default {
    name:"codeLogin",
    data(){
        return {
            show: true,
            moblieValue:"",  //手机输入框状态
            count: '',   //倒计时秒数
            timer: null,    //倒计时状态
            errorShow:false,    //手机号格式错误提示初始状态--不显示
            msg:"手机号格式有误",
        }
    },
    mounted() {
        this.getCode
    },
    methods:{

        pwdlogin() {
            this.$router.push({
                name: 'login',
                query: {

                }
            })
        },

        getCode(){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
            let sendCodeData={}
            let _this=this
            sendCodeData.mobile=_this.moblieValue
            console.log(sendCodeData)
            sendCode(sendCodeData).then(function (response) {
                console.log(sendCodeData)
                responseUtil.dealResponse(_this, response, () => {
                    console.log(response.data)
                })
            })
        },
        checkMoblie() {
            var re = /^1[3|4|5|7|8][0-9]{9}$/;
            var phone = this.moblieValue;
            if (phone === ""){  //如果手机号未输入，提示消息不会弹出
                this.errorShow = false
            }else if (re.test(phone)) { //如果手机号码符合，提示消息不会弹出
                this.errorShow = false
            }else if(!this.errorShow){
                const TIME_COUNT = 1; //提示消息显示时间为1秒
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.errorShow = true
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.errorShow = false;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            }else {
                this.errorShow = true
            }

        },

    }

}
</script>
<style scoped>
    .codeLogin{
        width: 100%;
    }
    .indexImg{
        font-size: 15px;
        width: 1.3rem;
        height: 1.1rem;
    }
    .img_title{
        margin-top: 1.25rem;
        margin-left: 7.5%;
        width: 2rem;
        height: 1.5rem;
    }
    /*登录页面顶部文字*/
    .welcomeDiv{
        margin-bottom: 1.2rem;
        margin-left: 7.5%;

    }
    .welcomeDiv div{
        font-size: 26px;
        color: #222222;
        font-size: 26px;
    }
    /*手机号码输入错误信息提示*/
    .errorDiv{
        float: left;
        margin-left: 7.5%;
        height: 2rem;
        width: 8rem;
        /*margin-top: 1rem;*/
    }
    .errorInfo{
        padding-left: 0.15rem;
        padding-top: 0.15rem;
        padding-bottom: 0.2rem;
        margin: 0 auto;
        color:white;
        border-radius: 0.1rem;
        width: 2.5rem;
        background-color:black;
        opacity: 0.55;
        font-size: 12px;

    }
    /*属性placeholder样式*/
    input::-webkit-input-placeholder{
        color: #D8D8D8;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        text-align: left;
        transform:translateY(5%);
    }

    .getCode{
        float: left;
        color:  #FF5D3B;
        border-radius: 0.2rem;
        background-color: #f8f8f8;
        font-size: 15px;
        height: 0.8rem;
        width: 2.5rem;
        border: 0.02rem solid transparent;
        background-image: linear-gradient(#f8f8f8,#f8f8f8),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
    }
    .count{
        float: left;
        color:  white;
        border-radius: 0.2rem;
        border: none;
        background-color: black;
        opacity: 0.2;
        font-size: 15px;
        height: 0.8rem;
        width: 2.5rem;
        /*border: 0.02rem solid transparent;*/
        /*background-image: linear-gradient(#D8D8D8,#D8D8D8),*/
        /*linear-gradient(to right,#ffc274,#ff5D3B);*/
        /*background-clip: padding-box ,border-box;*/
        /*background-origin: border-box;*/
    }
    .otherLogin{
        width: 7.7rem;
        margin-left: 7.5%;
        /*margin-bottom: 3rem;*/
        margin-top: 0.3rem;
    }
    /*点击密码登录*/
    .pwdLogin{
        float: left;
        color: #ff5D3B;
        font-size: 13px;
        font-family: PingFangSC-Semibold;
        /*margin-bottom: 2rem;*/
    }
    .loginBtn{
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 4%;
        height: 1.2rem;
        margin-bottom: 0.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;/*渐变色*/
    }
    .getWechat{
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        color: #21BA9B;
        width: 92%;
        margin-left: 4%;
        height: 1.2rem;
        background-color: #f8f8f8;
        border: 0.03rem solid #d8d8d8;
        border-radius: 0.15rem;/*圆角*/
        font-size: 16px;
    }
    .wechatLogo{
        height: 0.5rem;
        width: 0.6rem;
        vertical-align: sub
    }
    .btnDiv{
        margin-bottom: 1.5rem;
    }
    /*温馨提示样式*/
    .point{
        margin-top: 1rem;
        margin-left: 3%;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        width: 94%;
        font-size: 12px;
        color: #999999;
        text-indent: 0.6rem;/*首行缩进*/
    }
    .point span{
        color: steelblue;
    }
    /*竖线样式*/
    .line {
        float: left;
        padding-right: 0.15rem;
        padding-left: 0.15rem;
        color: #D8D8D8;
        font-size: 0.4111rem;
        margin-top: 0.26rem;
    }
    /*验证码输入框Div*/
    .inputCode {
        float: left;
        margin-top:20px;
        height:1rem;
        background: #f8f8f8;
        width: 8rem;
        margin-left: 7.5%;
        border-bottom: 0.05rem solid #EFEFEF ;
        margin-bottom: 0.5rem;
    }
    /*手机号码输入框DIV*/
    .inputPhone{
        /*margin-top:20px;*/
        float: left;
        height:1rem;
        background: #f8f8f8;
        width: 8rem;
        margin-left: 7.5%;
        border-bottom: 0.05rem solid #EFEFEF ;
    }
    /*手机号码输入框*/
    .inputPhone input {
        float: left;
        width:80%;
        height:0.58rem;
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 0.245rem;
        background: #f8f8f8;
    }
    /*验证码输入框*/
    .inputCode input {
        border: none;
        float: left;
        width:60%;
        height:0.58rem;
        vertical-align:middle;
        font-size: 16px;
        margin-top: 0.26rem;
        background: #f8f8f8;
        margin-left: 0.3rem;
        /*margin-right: 0.8rem;*/
    }
    .label{
        float: left;
        color: #ff5809;
        font-size: 16px;
        margin-top: 0.315rem;
        margin-left: 0.3rem
    }
</style>
